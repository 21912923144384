import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./About.css";
import Chefin from "../../images/elhamForouhar.png";

const ReadMore = ({ children, maxCharacterCount = 250 }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(false);
  const resultString = isReadMore
    ? text.toString().slice(0, maxCharacterCount)
    : true;
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {resultString}
      <div className="button" onClick={toggleReadMore}>
        <NavLink>{!isReadMore ? "بیشتر بخوانید" : "نمایش کمتر"}</NavLink>
      </div>
    </p>
  );
};

function About() {
  const navigate = useNavigate();
  const moveToHome = () => {
    navigate("/");
  };
  const moveToDocuments = () => {
    navigate("/documents");
  };
  return (
    <>
      <Helmet>
        {/* Meta Tags for Persian Content */}
        <meta
          name="description"
          content="با الهام مولائی فروهر، مشاور ارشد املاک در دبی، به کشف املاک منحصر به فرد در دبی، ابوظبی و راس الخیمه پرداخته و فرصت‌های سرمایه‌گذاری را بررسی کنید."
        />
        <meta
          name="keywords"
          content="الهام مولائی فروهر, مشاور املاک دبی, املاک دبی, سرمایه‌گذاری در دبی, خرید ملک در دبی, مشاور املاک"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://elhamprop.com/aboutus" />
        <meta property="og:title" content="درباره الهام مولائی فروهر" />
        <meta
          property="og:description"
          content="الهام مولائی فروهر، مشاور ارشد املاک در دبی، متخصص در فروش املاک منحصر به فرد در دبی، ابوظبی و راس الخیمه."
        />
        <meta
          property="og:image"
          content="https://www.elhamprop.com/elhamForouhar.png"
        />
        <meta property="og:url" content="https://www.elhamprop.com/aboutus" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="درباره الهام مولائی فروهر" />
        <meta
          name="twitter:description"
          content="با الهام مولائی فروهر، مشاور ارشد املاک در دبی، املاک منحصر به فرد در دبی، ابوظبی و راس الخیمه را کشف کنید."
        />
        <meta
          name="twitter:image"
          content="https://www.elhamprop.com/elhamForouhar.png"
        />

        {/* Schema Markup (JSON-LD) */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Person",
            name: "الهام مولائی فروهر",
            url: "https://www.elhamprop.com/aboutus",
            image: "https://www.elhamprop.com/elhamForouhar.png",
            description:
              "الهام مولائی فروهر، مشاور ارشد املاک در دبی، متخصص در فروش املاک منحصر به فرد در دبی، ابوظبی و راس الخیمه.",
            jobTitle: "مشاور ارشد املاک",
            worksFor: {
              "@type": "Organization",
              name: "الام پروپرتیز",
            },
            sameAs: [
              "https://www.linkedin.com/in/elhammolaeiforouhar/",
              "https://www.elhamprop.com",
            ],
          })}
        </script>
      </Helmet>
      <div className="section">
        <div className="container-about">
          <div className="content-section">
            <div className="title">{/* <h1>معرفی</h1> */}</div>
            <div className="content">
              <h3>
                <span className="first-child">
                  {" "}
                  مهندس الهام مولائى فروهر مشاور ارشد امور ملکی و سرمایه گذاری
                  در شهر زیبای دبی
                </span>
                <br />
                <br />
                <div className="second-child">
                  فروشنده املاک منحصر به فرد در شهرهای ابوظبی و راس الخیمه
                </div>
                <br />
                <br />
                خانم مهندس الهام مولائى فروهر در سال ۲۰۰۱ میلادی به کشور امارات
                متحده عربی مهاجرت کرد و فعالیت حرفه‌ای خود را در زمینه خرید و
                فروش املاک و مستغلات دبی از سال ۲۰۱۴ میلادی در شهر زیبای دبی
                آغاز نمود. ایشان هم اکنون به عنوان نماینده برتر شرکت‌های سازنده
                معتبر در دبی شناخته شده است. ایشان یک متخصص با درک عمیق از
                چشم‌انداز املاک دبی است. بینش و راهنمایی‌های او به سرمایه‌گذاران
                بسیاری کمک کرده است تا تصمیمات آگاهانه بگیرند و به بازده قابل
                توجهی در این بازار پویا دست یابند.
              </h3>
              <div className="btnWrapper">
                <button className="btn btnBest" onClick={moveToDocuments}>
                  مدارک
                </button>
                <button className="btn btnBest" onClick={moveToHome}>
                  بازگشت به صفحه اصلی
                </button>
              </div>
            </div>
          </div>
          <div className="image-section">
            <img src={Chefin} alt="about-us" />
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
