import { useRef } from "react";
import emailjs from "@emailjs/browser";
import { Helmet } from "react-helmet";
import "./Contact.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    console.log("Form:", form);
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          toast.success(
            "پیام شما دریافت شد و به زودی به شما پاسخ داده خواهد شد. با سپاس",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          console.log(result.text);
          e.target.reset();
        },
        (error) => {
          toast.error(
            "در ارسال پیام شما به ما مشکلی پیش آمده است، لطفاً بعداً دوباره تلاش کنید. پیام ارسال نشد!",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <Helmet>
        {/* SEO Meta Tags */}
        <meta
          name="description"
          content="برای مشاوره در خصوص فرصت‌های سرمایه‌گذاری و املاک در دبی، ابوظبی و راس الخیمه با الهام مولائی فروهر تماس بگیرید."
        />
        <meta
          name="keywords"
          content="تماس, الهام مولائی فروهر, املاک, دبی, ابوظبی, راس الخیمه, سرمایه‌گذاری"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://elhamprop.com/contact" />
        <meta property="og:title" content="تماس با الهام مولائی فروهر" />
        <meta
          property="og:description"
          content="برای مشاوره در خصوص املاک و سرمایه‌گذاری‌های دبی و امارات متحده عربی، با الهام مولائی فروهر تماس بگیرید."
        />
        <meta
          property="og:image"
          content="https://www.elhamprop.com/elhamForouhar.png"
        />
        <meta property="og:url" content="https://www.elhamprop.com/contact" />

        {/* Schema Markup for Contact Page */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "ContactPage",
            url: "https://www.elhamprop.com/contact",
            mainEntity: {
              "@type": "Organization",
              name: "Elham Properties",
              contactPoint: {
                "@type": "ContactPoint",
                telephone: "+971554289975",
                contactType: "customer service",
                areaServed: "Worldwide",
                availableLanguage: ["fa", "en", "de"],
              },
            },
          })}
        </script>
      </Helmet>
      <div className="contact-container">
        <form ref={form} onSubmit={sendEmail}>
          <label>نام و نام خانوادگی</label>
          <input type="text" name="user_name" required />
          <label>ایمیل</label>
          <input className="email" type="email" name="user_email" required />
          <label>تلفن</label>
          <input className="tel" type="tel" name="user_tel" required />
          <label>پیام</label>
          <textarea name="message" maxLength={10000} required />
          <input className="send-btn" type="submit" value="ارسال" required />
        </form>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default Contact;
